<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <div v-if="user">
        <v-card-title class="subtitle">Step 1: Name your organization</v-card-title>
        <v-card-text>      
          <v-alert type="info" class="mb-16">
            <p>This wizard is to <strong>create a new organization</strong> and configure and manage this organization.</p>
          </v-alert>
          <v-alert v-if="error" type="error">{{error}}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field 
              v-model="name" 
              :rules="nameRules" 
              label="How would you like to name your organization? (required)" 
              prepend-icon="fa-pencil-alt"
              required>
            </v-text-field>
            
            <div>
              <v-checkbox v-model="agreedToTerms" ref="termsCheckbox" :rules="cloudTermsRules">
                <template v-slot:label>
                  <div class="d-flex" style="flex-direction:column;">
                    <i18n path="account.register.terms_privacy_v2" tag="div" class="">
                      <a target="_blank" @click.stop="" href='/privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</a>
                      <a target="_blank" @click.stop="" href='/cloudterms'>Cloud Terms and Conditions</a>
                    </i18n>
                  </div>
                </template>
              </v-checkbox>
            </div>

            <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Create organization</v-btn>
            <v-btn text v-if="$store.state.context === 'microsoft-teams'" to="/apps/teams/content">Back</v-btn>

          </v-form>
        </v-card-text>   
      </div>    
      
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "CreateOrg",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: 'Create Organization', disabled: true },
      ],
      agreedToTerms: false,
      valid: true,
      error: null,
      name: "",
      type: null,
      nameRules: [
        //v => !!v || "Please enter the event name",
      ],
      cloudTermsRules: [
        v => !!v || "Please confirm you have read and agree to the Cloud Terms and Conditions.",
      ],
    };
  },
  async mounted() {
    await this.loadData();
      this.timeZoneOlson = DateTime.now().zoneName;
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          var response = await orgManagerService.create({
            name: this.name,
            license: this.$route.query.license,
          });
          this.$analytics.event('create_org');

          this.$router.push({ name: 'orgmanagerView', params: { id: response.data.id }, query: { mode: 'new' } });
        }
        catch (ex) {
          this.error = ex.response.data.msg;
          //console.log('ERR', ex, ex.response);
        }
      }
    },

    async loadData() {
      if (this.user) {
        //var response = await profileService.get();
        //this.profile = response.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  
</style>

